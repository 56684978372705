var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.root},[_c('VForm',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.onDraft.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('DisclosureAgreementSection',_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
var values = ref.values;
return _vm._l((values),function(item,index){return _c('VRow',{key:index,class:_vm.$style.row},_vm._l((['NDA_TEMPLATE_ID', 'APPROVAL_STATUS', 'NUMBER']),function(code){return _c('VCol',{key:code,attrs:{"cols":"12","sm":"6"}},[_c('TypedField',_vm._b({attrs:{"readonly":"","rules":[!!item[code]['value'] || 'Обязательное поле']}},'TypedField',Object.assign({}, item[code]),false))],1)}),1)})}}])},'DisclosureAgreementSection',Object.assign({}, _vm.values['BASIS_FOR_NDA']),false)),_c('DisclosureAgreementSection',_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
var values = ref.values;
return _vm._l((values),function(item,index){return _c('VRow',{key:index,class:_vm.$style.row},[_vm._l((['SECOND_NAME', 'FIRST_NAME', 'MIDDLE_NAME']),function(code){return _c('VCol',{key:code,attrs:{"cols":"12","sm":"6","md":"4"}},[_c('TypedField',_vm._b({attrs:{"rules":[!!item[code]['value'] || 'Обязательное поле']},on:{"input":function (value) { return item[code]['value'] = value; }}},'TypedField',Object.assign({}, item[code]),false))],1)}),_vm._l((['PHONE']),function(code){return _c('VCol',{key:code,attrs:{"cols":"12","sm":"6","md":"4"}},[_c('TypedField',_vm._b({attrs:{"rules":[
              !!item[code]['value'] || 'Обязательное поле',
              /^[+]?\d*$/.test(item[code]['value']) || 'Укажите корректный телефон' ]},on:{"input":function (value) { return item[code]['value'] = value; }}},'TypedField',Object.assign({}, item[code]),false))],1)}),_vm._l((['EMAIL']),function(code){return _c('VCol',{key:code,attrs:{"cols":"12","sm":"6","md":"4"}},[_c('TypedField',_vm._b({attrs:{"rules":[
              !!item[code]['value'] || 'Обязательное поле',
              /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(item[code]['value']) || 'Укажите корректный email' ]},on:{"input":function (value) { return item[code]['value'] = value; }}},'TypedField',Object.assign({}, item[code]),false))],1)})],2)})}}])},'DisclosureAgreementSection',Object.assign({}, _vm.fields['COUNTERPARTY']),false)),_c('DisclosureAgreementSection',_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var values = ref.values;
return _vm._l((values),function(item,index){return _c('VRow',{key:index,class:_vm.$style.row},_vm._l((['INITIATOR_EMAIL']),function(code){return _c('VCol',{key:code,attrs:{"cols":"12","sm":"6","md":"4"}},[_c('TypedField',_vm._b({attrs:{"rules":[
              !!item[code]['value'] || 'Обязательное поле',
              /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(item[code]['value']) || 'Укажите корректный email',
              /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9_.+-]+\.)?evraz\.com$/.test(item[code]['value']) || 'Email может быть в домене evraz.com' ]},on:{"input":function (value) { return item[code]['value'] = value; }}},'TypedField',Object.assign({}, item[code]),false))],1)}),1)})}}])},'DisclosureAgreementSection',Object.assign({}, _vm.fields['RESPONSIBLE_INITIATOR']),false)),_c('DisclosureAgreementSection',_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
           var values = ref.values;
return [_vm._l((values),function(item,index){return _c('VRow',{key:index,class:_vm.$style.row},[_vm._l((['SECOND_NAME', 'FIRST_NAME', 'MIDDLE_NAME']),function(code){return _c('VCol',{key:code,attrs:{"cols":"12","sm":"6","md":"4"}},[_c('TypedField',_vm._b({attrs:{"rules":[!!item[code]['value'] || 'Обязательное поле']},on:{"input":function (value) { return item[code]['value'] = value; }}},'TypedField',Object.assign({}, item[code]),false))],1)}),_vm._l((['EMAIL']),function(code){return _c('VCol',{key:code,attrs:{"cols":"12","sm":"6","md":"4"}},[_c('TypedField',_vm._b({attrs:{"rules":[
              !!item[code]['value'] || 'Обязательное поле',
              /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(item[code]['value']) || 'Укажите корректный email' ]},on:{"input":function (value) { return item[code]['value'] = value; }}},'TypedField',Object.assign({}, item[code]),false))],1)}),(values.length > 1)?_c('VCol',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('VBtn',{attrs:{"depressed":"","small":""},on:{"click":function($event){return _vm.onSplice(_vm.fields['RESPONSIBLE_COUNTERPARTY']['values'], index)}}},[_vm._v("Удалить")])],1):_vm._e()],2)}),_c('div',{staticClass:"pt-5"},[_c('VBtn',{attrs:{"color":"primary","depressed":""},on:{"click":function($event){return _vm.onPush(_vm.fields['RESPONSIBLE_COUNTERPARTY']['values'])}}},[_vm._v(" Добавить ")])],1)]}}])},'DisclosureAgreementSection',Object.assign({}, _vm.fields['RESPONSIBLE_COUNTERPARTY']),false)),_c('DisclosureAgreementSection',_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var values = ref.values;
return _vm._l((values),function(item,index){return _c('VRow',{key:index,class:_vm.$style.row},[_vm._l((['SIGNER_LEGAL_BASIS']),function(code){return _c('VCol',{key:code,attrs:{"cols":"12","sm":"6","md":"4"}},[_c('TypedField',_vm._b({attrs:{"type":"RADIO","rules":[!!item[code]['value'] || 'Обязательное поле']},on:{"input":function (value) { return item[code]['value'] = value; }}},'TypedField',Object.assign({}, item[code]),false))],1)}),(item['SIGNER_LEGAL_BASIS']['value'] === 'POA')?_c('VCol',{attrs:{"cols":"12"}},[_c('DisclosureAgreementSection',_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var values = ref.values;
return _vm._l((values),function(item,index){return _c('VRow',{key:index,class:_vm.$style.row},[_vm._l((['NUMBER']),function(code){return _c('VCol',{key:code,attrs:{"cols":"12","sm":"6","md":"4"}},[_c('TypedField',_vm._b({attrs:{"rules":[!!item[code]['value'] || 'Обязательное поле']},on:{"input":function (value) { return item[code]['value'] = value; }}},'TypedField',Object.assign({}, item[code]),false))],1)}),_vm._l((['DATE']),function(code){return _c('VCol',{key:code,attrs:{"cols":"12","sm":"6","md":"4"}},[_c('TypedField',_vm._b({attrs:{"type":"DATE","max":_vm.nowISO,"rules":[!!item[code]['value'] || 'Обязательное поле']},on:{"input":function (value) { return item[code]['value'] = value; }}},'TypedField',Object.assign({}, item[code]),false))],1)})],2)})}}],null,true)},'DisclosureAgreementSection',Object.assign({}, _vm.fields['POA_DATA']),false)),_c('DisclosureAgreementSection',_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var values = ref.values;
return [_vm._l((values),function(item,index){return _c('VRow',{key:index,class:_vm.$style.row},[_vm._l((['FILES']),function(code){return _c('VCol',{key:code,attrs:{"cols":"12","sm":"6","md":"4"}},[_c('FileInput',_vm._b({attrs:{"type":"POWER_OF_ATTORNEY","rules":function (ref) {
                       var value = ref.value;
                       var size = ref.size; if ( size === void 0 ) size = 0;

                       return [
                    !!value || 'Обязательное поле',
                    (+size < 52428800) || 'Размер файла не должен превышать 50Mb' ];
            }},on:{"input":function (value) { return item[code]['value'] = value; }}},'FileInput',Object.assign({}, item[code]),false))],1)}),(values.length > 1)?_c('VCol',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('VBtn',{attrs:{"depressed":"","small":""},on:{"click":function($event){return _vm.onSplice(_vm.fields['POA_FILES']['values'], index)}}},[_vm._v("Удалить")])],1):_vm._e()],2)}),_c('div',{staticClass:"pt-5"},[_c('VBtn',{attrs:{"color":"primary","depressed":""},on:{"click":function($event){return _vm.onPush(_vm.fields['POA_FILES']['values'])}}},[_vm._v(" Добавить ")])],1)]}}],null,true)},'DisclosureAgreementSection',Object.assign({}, _vm.fields['POA_FILES']),false))],1):_vm._e()],2)})}}])},'DisclosureAgreementSection',Object.assign({}, _vm.fields['SIGNER_TYPE']),false)),_c('DisclosureAgreementSection',_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var values = ref.values;
return _vm._l((values),function(item,index){return _c('VRow',{key:index,class:_vm.$style.row},[_vm._l((['NDA_PURPOSE']),function(code){return _c('VCol',{key:code,attrs:{"cols":"12","sm":"6","md":"4"}},[_c('TypedField',_vm._b({attrs:{"type":"RADIO","rules":[!!item[code]['value'] || 'Обязательное поле']},on:{"input":function (value) { return item[code]['value'] = value; }}},'TypedField',Object.assign({}, item[code]),false))],1)}),(item['NDA_PURPOSE']['value'] === 'OPERATING_ACTIVITIES')?_c('VCol',{attrs:{"cols":"12"}},[_c('DisclosureAgreementSection',_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var values = ref.values;
return _vm._l((values),function(item,index){return _c('VRow',{key:index,class:_vm.$style.row},_vm._l((['BASE']),function(code){return _c('VCol',{key:code,attrs:{"cols":"12"}},[_c('TypedField',_vm._b({attrs:{"type":"TEXTAREA","placeholder":"передачи информации при реализации проекта 9999 «Оформление соглашений о конфиденциальности»"},on:{"input":function (value) { return item[code]['value'] = value; }}},'TypedField',Object.assign({}, item[code]),false))],1)}),1)})}}],null,true)},'DisclosureAgreementSection',Object.assign({}, _vm.fields['BASIS_FOR_CONCLUDING']),false))],1):_vm._e()],2)})}}])},'DisclosureAgreementSection',Object.assign({}, _vm.fields['NDA_PURPOSE_SECTION']),false)),_c('DisclosureAgreementSection',_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var values = ref.values;
return _vm._l((values),function(item,index){return _c('VRow',{key:index,class:_vm.$style.row},[_vm._l((['NDA_FORM']),function(code){return _c('VCol',{key:code,attrs:{"cols":"12","sm":"6","md":"3","lg":"2"}},[_c('TypedField',_vm._b({attrs:{"type":"RADIO","rules":[!!item[code]['value'] || 'Обязательное поле']},on:{"input":function (value) { return item[code]['value'] = value; }}},'TypedField',Object.assign({}, item[code]),false))],1)}),_c('VCol',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('div',{staticClass:"mb-1 mt-1"},[_vm._v("Формы для ознакомления")]),_c('a',{staticClass:"mr-3 d-inline-block",attrs:{"download":"","target":"_blank","href":_vm.url('/nda/assets/operational-activity.docx')}},[_vm._v("Операционная деятельность")]),_c('a',{staticClass:"d-inline-block",attrs:{"download":"","target":"_blank","href":_vm.url('/nda/assets/investment-activity.docx')}},[_vm._v("Инвест. деятельность")])]),(item['NDA_FORM']['value'] === 'STANDARD')?_c('VCol',{attrs:{"cols":"12"}},[_c('DisclosureAgreementSection',_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var values = ref.values;
return _vm._l((values),function(item,index){return _c('VRow',{key:index,class:_vm.$style.row},_vm._l((['ORGANIZATIONS']),function(code){return _c('VCol',{key:code,attrs:{"cols":"12"}},[_c('VRow',{key:index,attrs:{"no-gutters":""}},[_vm._l((item[code]['options']),function(ref){
                  var value = ref.value;
                  var text = ref.text;
return _c('VCol',{key:value,attrs:{"cols":"12","md":"6","lg":"4","xl":"3"}},[_c('VCheckbox',{attrs:{"label":text,"value":value,"rules":[item[code]['value'].length > 0 || 'Обязательное поле.'],"dense":"","hide-details":""},model:{value:(item[code]['value']),callback:function ($$v) {_vm.$set(item[code], 'value', $$v)},expression:"item[code]['value']"}})],1)}),(!item[code]['value'].length && !_vm.valid)?_c('VCol',{staticClass:"red--text ",attrs:{"cols":"12"}},[_c('div',{staticClass:"v-messages"},[_vm._v(" "+_vm._s(!!item[code]['value'].length || 'Обязательное поле')+" ")])]):_vm._e()],2)],1)}),1)})}}],null,true)},'DisclosureAgreementSection',Object.assign({}, _vm.fields['ORGANIZATIONS_EVRAZ']),false))],1):_vm._e(),(item['NDA_FORM']['value'] === 'PARTNER')?_c('VCol',{attrs:{"cols":"12"}},[_c('DisclosureAgreementSection',_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var values = ref.values;
return _vm._l((values),function(item,index){return _c('VRow',{key:index,class:_vm.$style.row},[_vm._l((['FILE']),function(code){return _c('VCol',{key:code,attrs:{"cols":"12","sm":"6","md":"4"}},[_c('FileInput',_vm._b({attrs:{"type":"PARTNER_NDA","rules":function (ref) {
                             var value = ref.value;
                             var size = ref.size; if ( size === void 0 ) size = 0;

                             return [
                    !!value || 'Обязательное поле',
                    /doc[x]?/.test(value) || 'Только редактируемый формат (doc, docx)',
                    (+size < 52428800) || 'Размер файла не должен превышать 50Mb' ];
                  }},on:{"input":function (value) { return item[code]['value'] = value; }}},'FileInput',Object.assign({}, item[code]),false)),_c('div',{staticClass:"pt-1"},[_vm._v(" Допустимый формат doc, docx ")])],1)}),_vm._l((['DATE']),function(code){return _c('VCol',{key:code,attrs:{"cols":"12","sm":"6","md":"4"}},[_c('TypedField',_vm._b({attrs:{"type":"DATE","rules":[!!item[code]['value'] || 'Обязательное поле'],"min":_vm.nowISO},on:{"input":function (value) { return item[code]['value'] = value; }}},'TypedField',Object.assign({}, item[code]),false)),_c('div',{staticClass:"pt-1"},[_vm._v(" Срок действия должен соответствовать дате в тексте соглашения ")])],1)})],2)})}}],null,true)},'DisclosureAgreementSection',Object.assign({}, _vm.fields['NDA_FORM_PARTNER_DATA']),false))],1):_vm._e()],2)})}}])},'DisclosureAgreementSection',Object.assign({}, _vm.fields['NDA_FORM_SECTION']),false)),_c('DisclosureAgreementSection',_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var values = ref.values;
return [_vm._l((values),function(item,index){return _c('VRow',{key:index,class:_vm.$style.row},[_vm._l((['FILES']),function(code){return _c('VCol',{key:code,attrs:{"cols":"12","sm":"6","md":"4"}},[_c('FileInput',_vm._b({attrs:{"type":"RELATED_DOC","rules":function (ref) {
                             var size = ref.size; if ( size === void 0 ) size = 0;

                             return [
              (+size < 52428800) || 'Размер файла не должен превышать 50Mb' ];
                  }},on:{"input":function (value) { return item[code]['value'] = value; }}},'FileInput',Object.assign({}, item[code]),false))],1)}),(values.length > 1)?_c('VCol',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('VBtn',{attrs:{"depressed":"","small":""},on:{"click":function($event){return _vm.onSplice(_vm.fields['DOCUMENTS_ACCOMPANY']['values'], index)}}},[_vm._v("Удалить")])],1):_vm._e()],2)}),_c('div',{staticClass:"pt-5"},[_c('VBtn',{attrs:{"color":"primary","depressed":""},on:{"click":function($event){return _vm.onPush(_vm.fields['DOCUMENTS_ACCOMPANY']['values'])}}},[_vm._v(" Добавить ")])],1)]}}])},'DisclosureAgreementSection',Object.assign({}, _vm.fields['DOCUMENTS_ACCOMPANY']),false)),_c('VFooter',{attrs:{"app":""}},[_c('div',{staticClass:"grow py-3"},[_c('VBtn',{staticClass:"mr-3",attrs:{"color":"primary","depressed":"","disabled":!_vm.valid},on:{"click":_vm.onAgreement}},[(_vm.$vuetify.breakpoint.name === 'xs')?_c('VIcon',[_vm._v("mdi-send")]):[_vm._v("Отправить на согласование")]],2),_c('VBtn',{staticClass:"mr-3",attrs:{"color":"primary","depressed":"","disabled":!_vm.viable},on:{"click":_vm.onDraft}},[(_vm.$vuetify.breakpoint.name === 'xs')?_c('VIcon',[_vm._v("mdi-cloud-upload-outline")]):[_vm._v("Сохранить черновик")]],2),_c('VBtn',{staticClass:"mr-3",attrs:{"color":"secondary","depressed":""},on:{"click":_vm.onBackPage}},[_vm._v(" Назад ")])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }